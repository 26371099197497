import Cookies from "universal-cookie";
import {
  CookiesKeyNames,
  getPathKey,
  isCookiesEnabled,
} from "../contexts/CookiesContext";

export const getToken = (advId = "") => {
  const urlParams = new URLSearchParams(window.location.search);
  const cookies = new Cookies();

  const tokenFromUrl = urlParams.get("token");
  if (tokenFromUrl) return tokenFromUrl;

  const pathKey = advId || getPathKey();

  const tokenFromStorage = (() => {

    if (isCookiesEnabled) {
      return (
        cookies.get(pathKey)?.token ?
        cookies.get(pathKey)?.token :
        cookies.get(pathKey)?.publisher_token ?
        cookies.get(pathKey)?.publisher_token :
        null
      );
    }

    try {
      const storedData = localStorage.getItem(pathKey);
      return storedData ? JSON.parse(storedData).token : null;
    } catch (error) {
      console.error("Error parsing token from localStorage:", error);
      return null;
    }
  })();

  return tokenFromStorage;
};
