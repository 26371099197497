import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getAllRoles(advId = "") {
  try {
    const token = getToken(advId);
    axios.defaults.headers.common["authorization"] = token;
    const response = await axios.get(
      process.env.REACT_APP_HUB_URL + "/api/roles/all"
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export default getAllRoles;
