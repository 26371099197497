import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import "ag-grid-enterprise";
import {
  ClientSideRowModelModule,
  ModuleRegistry
} from "ag-grid-community";
import { LicenseManager, RowGroupingModule } from "ag-grid-enterprise";
    
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
    <div className="main-backdrop"></div>
  </React.StrictMode>
);

reportWebVitals();
