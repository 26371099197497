import CryptoJS from "crypto-js";

const getEnvVar = (name) => {
  const value = process.env[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
};

const key = getEnvVar("REACT_APP_SECRET_KEY_256");

// Функція для шифрування
export const encrypt = (text) => {
  if (!text) return;
  const encrypted = CryptoJS.AES.encrypt(text.toString(), key).toString();
  return encrypted;
};

// Функція для дешифрування
export const decrypt = (data) => {
  if (!data) return;

  // Заміна пробілів на плюси
  const sanitizedData = data.replace(/ /g, "+");

  const bytes = CryptoJS.AES.decrypt(sanitizedData, key);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);

  if (!decrypted) {
    throw new Error("Decryption failed or data is corrupted");
  }

  return decrypted;
};
