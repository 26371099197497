import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";
import { endOfDay, startOfDay, subDays } from "date-fns";

async function getPlacementClicksByCluster(inventoryId, campaignId) {
    try {        
        const token = getToken();
        const currentPeriod = [
            startOfDay(subDays(new Date(), 30)), endOfDay(new Date())
        ];

        axiosInstance.defaults.headers.common['authorization'] = token;

        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/placement/cluster/clicks', {
            inventory_id: Number(inventoryId),
            campaign_id: Number(campaignId),
            current_period: [
                normalizeToUTC(currentPeriod[0]).getTime(),
                normalizeToUTC(currentPeriod[1]).getTime()
            ]
        })

        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPlacementClicksByCluster;
