/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { store } from "./store";

// import css
import "./assets/css/remixicon.css";

// import rsuite css
import "./scss/rsuite-custom.scss";
import "rsuite/dist/rsuite-no-reset.min.css";

import { Auth } from "./components/Auth";
import { TrackUserActions } from "./mixpanel/TrackUserActions";
import { abbreviationsMap } from "./modules/maps";
import {
  CookiesKeyNames,
  CookiesProvider,
  isCookiesEnabled,
} from "./contexts/CookiesContext";
import { isIOSOrSafari } from "./helpers/is-ios-or-safari";
import Cookies from "universal-cookie";
import { decrypt } from "./helpers/crypto";
import RedirectsProvider from "./contexts/RedirectsContext";

const cookies = new Cookies();

async function notifyUser(
  notificationText = "Thank you for enabling notifications!",
  options = {
    body: "Now you will receive notifications from Revbox",
    icon: "https://revbox-email-icons.s3.eu-central-1.amazonaws.com/logo_icon-white.png",
    dir: "ltr",
  }
) {
  // todo: clean up this function
  if (!("Notification" in window)) {
    alert("Browser does not support notifications");
  } else if (Notification.permission === "granted") {
    const notification = (new Notification(notificationText, options).onclick =
      function () {
        window.open("https://github.com/revbox/revbox-hub/tree/develop");
      });
  } else if (Notification.permission !== "denied") {
    await Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const notification = new Notification(notificationText, options);
      }
    });
  }
}

function getPublisherId() {
  const searchParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;
  const pubParam = searchParams.get("pub");

  const publisherIdFromCookies = isCookiesEnabled
    ? cookies.get("pub")?.[CookiesKeyNames.publisherId]
    : JSON.parse(localStorage.getItem("pub") || "{}")[
        CookiesKeyNames.publisherId
      ];

  if (pubParam) {
    if (abbreviationsMap[pubParam]) {
      return abbreviationsMap[pubParam];
    } else {
      return pubParam;
    }
  }

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (urlDomain === "app.netnetix.com") {
    return 34;
  }

  if (publisherIdFromCookies) {
    return Number(publisherIdFromCookies);
  }

  return 1;
}

const getPathKey = () => {
  if (typeof window === "undefined") return "pub"; // Для середовищ без `window`

  const { pathname = "", search } = window.location;
  let advId = null;

  if (search) {
    const params = new URLSearchParams(search);
    let encryptedAdvId = params.get("advId");
    if (encryptedAdvId) {
      encryptedAdvId = encryptedAdvId.replace(/\+/g, "%20"); // Заміна плюса на пробіл
      encryptedAdvId = decodeURIComponent(encryptedAdvId); // Декодуємо знову
      advId = decrypt(encryptedAdvId);
    }
  }

  const pathKey = pathname.includes("/adv") ? advId : "pub";
  return pathKey;
};

// add pasword to App
export default function App() {
  const publisher_id = getPublisherId();
  const [userResponded, setUserResponded] = useState(false);

  useEffect(() => {
    if (publisher_id) {
      let skinMode = isCookiesEnabled
        ? cookies.get(getPathKey())[CookiesKeyNames.skinMode]
        : JSON.parse(localStorage.getItem(getPathKey()) || "{}")[
            CookiesKeyNames.skinMode
          ];
      let HTMLTag = document.querySelector("html");

      import(`./modules/styles/${publisher_id}.scss`)
        .then(() => console.log("Stylesheet loaded"))
        .catch(() => {
          import(`./modules/styles/default.scss`)
            .then(() => console.log("Default stylesheet loaded"))
            .catch(() => console.log("No stylesheet loaded"));
        });

      if (skinMode) {
        HTMLTag.setAttribute("data-skin", skinMode);
      }
    } else {
      import(`./modules/styles/default.scss`)
        .then(() => console.log("Default stylesheet loaded"))
        .catch(() => console.log("No stylesheet loaded"));
    }
  }, [publisher_id]);

  useEffect(() => {
    if (!isIOSOrSafari()) {
      if (!userResponded && Notification.permission !== "granted") {
        notifyUser().then(() => {
          setUserResponded(true);
        });
      }
    } else {
      console.log("Not running notifyUser since the browser is Safari or iOS.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <button onClick={() => notifyUser()}>CLICK ME</button> */}
        <CookiesProvider>
          <Provider store={store}>
            <React.Fragment>
              <BrowserRouter>
                <RedirectsProvider>
                  <TrackUserActions />
                  <Routes>
                    {publicRoutes.map((route, index) => {
                      return (
                        <Route
                          path={route.path}
                          element={route.element}
                          key={index}
                        />
                      );
                    })}
                    <Route
                      path="/"
                      element={
                        <Auth>
                          <Main />
                        </Auth>
                      }
                    >
                      {protectedRoutes.map((route, index) => {
                        return (
                          <Route
                            path={route.path}
                            element={route.element}
                            key={index}
                          />
                        );
                      })}
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </RedirectsProvider>
              </BrowserRouter>
              <ToastContainer />
            </React.Fragment>
          </Provider>
        </CookiesProvider>
    </>
  );
}
