import { CookiesKeyNames, useCookiesContext } from "../../../contexts/CookiesContext";

export function NameRenderer({ value }) {
    const {
        cookiesData,
        getPathKey,
    } = useCookiesContext();
    const publisherId = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];

    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    if (publisherId != 67 && publisherId != 10) {
        return (
            <div className="table-item-name">
                {name} <span className='badge bg-outline-secondary badg'>{visits} Views</span>
            </div>
        );
    } else {
        return (
            <div className="table-item-name">
                {name}
            </div>
        );
    }
}

export function NameCellTooltipRenderer({ value }) {
    const name = value.split("@")[0];
    const visits = value.split("@")[1];

    return `Current invetory recived ${visits} visits per last 30 days`;
}
 