import React, { useEffect, useState } from "react";
import { getPeriodAdvertiserPerformance } from "../../../services/micro";
import { Card, Col, Nav, Placeholder } from "react-bootstrap";
import { AgSplittedTable } from "../../../components/AgSplittedTable";
import { StatusRenderer } from "../../inventory/components/StatusRenderer";
import { CurrentBudgetRenderer } from "./components/currentBudgetRenderer";
import { useRedirectsContext } from "../../../contexts/RedirectsContext";

const customColumns = [
  {
    field: "status",
    cellRenderer: StatusRenderer,
  },
  {
    field: "budget",
    cellRenderer: CurrentBudgetRenderer,
  },
];

const colWidths = {
  ID: 100,
  campaign: 120,
  status: 120,
  budget: 120,
  credits: 120,
  bids: 120,
  impressions: 150,
  freeClicks: 150,
  paidClicks: 150,
  averagePos: 150,
  averageCPC: 150,
  topCPC: 150,
  totalSpend: 150,
};

export default function AdvertiserPerformance(props) {
  const { currentPeriod, previousPeriod, skin } = props;
  const [response, setResponse] = useState();
  const [status, setStatus] = useState(1);

  const [countByStatus, setCountByStatus] = useState({
    activeRecords: 0,
    inactiveRecords: 0,
    pausedRecords: 0,
    total: 0,
  });

  const {
    openCampaignAdvAunctions,
    openCampaignAdvAnalytics,
    openCampaignEdit,
  } = useRedirectsContext();

  const getContextMenuItems = (params) => {
    var result = [
      {
        name: "Edit " + params.node.data.campaign,
        action: () => {
          openCampaignEdit(params.node.data.ID);
        },
      },
      "separator",
      {
        name: "Open " + params.node.data.campaign + " Auctions",
        action: () => {
          openCampaignAdvAunctions(params.node.data.ID);
        },
      },
      {
        name: "Open " + params.node.data.campaign + " Analytics",
        action: () => {
          openCampaignAdvAnalytics(params.node.data.ID);
        },
      },
      "separator",
      "copy",
    ];
    return result;
  };

  const onRowClick = (event) => {
    openCampaignAdvAunctions(event.data.ID);
  };

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (
        currentPeriod[0] &&
        currentPeriod[1] &&
        previousPeriod[0] &&
        previousPeriod[1]
      ) {
        getPeriodAdvertiserPerformance(currentPeriod, previousPeriod).then(
          (res) => {
            setResponse(res);
          }
        );
      }
    }
  }, [props]);

  if (response) {
    const currentColumns = response.data.columns.slice(0, 6);
    const periodColumns = response.data.columns.slice(6);

    const classifiedColumns = {
      currentColumns,
      periodColumns,
    };

    return (
      <React.Fragment>
        <Col xs="12">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Campaign Performance</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
            </Card.Header>
            <Card.Body className="p-3 p-xl-3">
              <Nav className="nav-line mb-4 ml-4">
                {
                  <Nav.Link
                    href="#active"
                    onClick={() => setStatus(1)}
                    className={status === 1 ? "active" : ""}
                  >
                    Active ({countByStatus.activeRecords})
                  </Nav.Link>
                }
                {
                  <Nav.Link href="#paused" onClick={() => setStatus(2)}>
                    Paused ({countByStatus.pausedRecords})
                  </Nav.Link>
                }
                {
                  <Nav.Link href="#inactive" onClick={() => setStatus(3)}>
                    Inactive ({countByStatus.inactiveRecords})
                  </Nav.Link>
                }
                {
                  <Nav.Link href="#all" onClick={() => setStatus(0)}>
                    All ({countByStatus.total})
                  </Nav.Link>
                }
              </Nav>
              <AgSplittedTable
                classifiedColumns={classifiedColumns}
                getData={getPeriodAdvertiserPerformance}
                currentPeriod={currentPeriod}
                previousPeriod={previousPeriod}
                setCountByStatus={setCountByStatus}
                status={status}
                customColumns={customColumns}
                getContextMenuItems={getContextMenuItems}
                skin={skin}
                colWidths={colWidths}
                onRowClick={onRowClick}
              />
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
