import Cookies from "universal-cookie";
import { CookiesKeyNames, isCookiesEnabled } from "../contexts/CookiesContext";
import { encrypt } from "./crypto";

export const addPubParamToUrl = (url, addPub = false, advertiserId) => {
  const cookies = new Cookies();
  const urlObj = new URL(url, window.location.origin);
  const searchParams = new URLSearchParams(urlObj.search);

  const abbreviation = isCookiesEnabled
    ? cookies.get(advertiserId)?.[CookiesKeyNames.abbreviation]
    : localStorage.getItem(advertiserId)?.[CookiesKeyNames.abbreviation];

  if (advertiserId) {
    const encryptedAdvId = encrypt(advertiserId);
    searchParams.append("advId", encryptedAdvId);
    urlObj.search = searchParams.toString();
  }

  if (!abbreviation || !addPub) return urlObj.pathname + urlObj.search;

  if (!searchParams.has("pub")) {
    searchParams.append("pub", addPub);
    urlObj.search = searchParams.toString();
  }

  return urlObj.pathname + urlObj.search;
};
