export function MissedClicksForecastRenderer({ value }) {
    if (!value) {
        return null;
    }

    const missed_clicks = Number(value.split("@")[0]);
    const missed_clicks_position_one = Number(value.split("@")[1]);
    const est_click_opportunity = Number(value.split("@")[2]);
    const items_amount = Number(value.split("@")[3]);
    const min_increment = Number(value.split("@")[4]);
    const top_bid = Number(value.split("@")[5]);
    const cpc = Number(value.split("@")[6]);
    const status = value.split("@")[7];
    const position = Number(value.split("@")[8]);

    let percentage = 0;
    let diff = 0;

    if (missed_clicks_position_one > missed_clicks) {
        diff = missed_clicks_position_one - missed_clicks;
        percentage = (missed_clicks / (missed_clicks_position_one + missed_clicks)) * 100;
    } else {
        diff = missed_clicks;
        percentage = 5;
    }

    if (status?.toUpperCase() === "INACTIVE") {
        diff = missed_clicks_position_one;
        percentage = 5;
    }

    if (percentage < 5) {
        percentage = 5;
    }

    const clicks = diff === 1 ? 'click' : 'clicks';

    // if (missed_clicks === 0 && status?.toUpperCase() !== "INACTIVE") {
    //     return (
    //         <div class="d-flex align-items-center">
    //             <div className="progress" style={{height: '14px', width: '80px'}}>
    //                 <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
    //                 </div>
    //             </div>
    //             <div style={{width: '70px', marginLeft: '10px'}}>
    //                 Optimized
    //             </div>
    //         </div>
    //     );
    // }

    if (position == 1 && status?.toUpperCase() !== "INACTIVE") {
        return (
            <div class="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    Optimized
                </div>
            </div>
        );
    }

    if (status?.toUpperCase() === "INACTIVE") {
        return (
            <div class="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "5%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {diff} {clicks} <i className="ri-information-line text-danger"></i>
                </div>
            </div>
        );
    }

    if (status?.toUpperCase() === "DRAFT" || status?.toUpperCase() === "PENDING") {
        return (
            <div class="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "5%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {diff} {clicks} <i className="ri-information-line text-danger"></i>
                </div>
            </div>
        );
    }

    if (diff <= 0) {
        // return (
        //     <div class="d-flex align-items-center">
        //         <div className="progress" style={{height: '14px', width: '80px'}}>
        //             <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
        //             </div>
        //         </div>
        //         <div style={{width: '70px', marginLeft: '10px'}}>
        //             Optimized
        //         </div>
        //     </div>
        // );
        return (
            <div class="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: percentage + "%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {diff} {clicks} <i className="ri-information-line text-secondary"></i>
                </div>
            </div>
        );
    } else if (diff > 0 && percentage > 30) {
        return (
            <div class="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-primary" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: percentage + "%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {diff} {clicks} <i className="ri-information-line text-secondary"></i>
                </div>
            </div>
        );
    } else {
        return (
            <div class="d-flex align-items-center">
                <div className="progress" style={{height: '14px', width: '80px'}}>
                    <div role="progressbar" className="progress-bar bg-danger" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width: percentage + "%"}}>
                    </div>
                </div>
                <div style={{width: '70px', marginLeft: '10px'}}>
                    {diff} {clicks} <i className="ri-information-line text-danger"></i>
                </div>
            </div>
        );
    }
}
  

export function MissedClicksForecastCellTooltipRenderer({ value }) {
    if (!value) {
        return null;
    }
    // Your current position received 596 fewer clicks over the last 30 days than the top position.

    const missed_clicks = Number(value.split("@")[0]);
    const missed_clicks_position_one = Number(value.split("@")[1]);
    const est_click_opportunity = Number(value.split("@")[2]);
    const items_amount = Number(value.split("@")[3]);
    const min_increment = Number(value.split("@")[4]);
    const top_bid = Number(value.split("@")[5]);
    const cpc = Number(value.split("@")[6]);
    const status = value.split("@")[7];
    const position = Number(value.split("@")[8]);

    let diff = 0;

    if (missed_clicks_position_one > missed_clicks) {
        diff = missed_clicks_position_one - missed_clicks;
    } else {
        diff = missed_clicks;
    }

    if (status?.toUpperCase() === "INACTIVE") {
        const incerement = top_bid + min_increment;

        if (missed_clicks_position_one === 0) {
            return ``;
        }

        return `Bid $${incerement} to earn the #1 position and generate ~${missed_clicks_position_one} clicks over the next 30 days`;
    }

    if (position === 1) {
        return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${missed_clicks_position_one} clicks/mo`;
    }

    else if (position <= items_amount) {
        const incerement = (top_bid - cpc) + min_increment;

        if (incerement > 0) {
            return `Your current position received ${diff} fewer clicks over the last 30 days than the top position. Increase your bid by $${incerement} to receive ${est_click_opportunity}x the click volume.`;
        } else {
            return `You are in the top position winning the most clicks in the inventory. Monthly clicks forecast = ${missed_clicks_position_one} clicks/mo`;
        }
    }

    else {
        const incerement = top_bid + min_increment;

        if (missed_clicks_position_one === 0) {
            return ``;
        }

        return `Bid $${incerement} to earn the #1 position and generate ~${missed_clicks_position_one} clicks over the next 30 days`;
    }
}