import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { login } from "../services/micro";
import { clearUser, setUser } from "../features/user";
import { clearAdvertiser } from "../features/advertiser";
import { logosModule } from "../modules/logos";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

function getPublisherId() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (urlDomain === "app.netnetix.com") {
    return 34;
  }

  return urlParams.get("pub") || 1;
}

export const Signin = () => {
  const publisher_id = getPublisherId();
  const refresh = window.location.search.includes("refresh=true");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateCookiesProperty, removeCookiesProperty, clearCookies, cookiesData, getPathKey } =
    useCookiesContext();

  useEffect(() => {
    // todo: clean only important cookies
    // sessionStorage.clear();
    // clearCookies();
    // clearUser();
    // clearAdvertiser();

    const pathKey = getPathKey();

    if (publisher_id) {
      updateCookiesProperty(CookiesKeyNames.publisherId, publisher_id);

      navigate("/pages/signin?pub=" + publisher_id);
    }

    if (cookiesData[pathKey]?.[CookiesKeyNames.publisherId]) {
      navigate(
        "/pages/signin?pub=" + cookiesData[pathKey]?.[CookiesKeyNames.publisherId]
      );

      if (refresh) {
        navigate(0);
      }
    }
  }, [publisher_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please enter your email and password.");
      return;
    }

    login(email, password)
      .then((res) => {
        if (res.success) {
          toast.success("Login successful. Redirecting...");
          dispatch(setUser(res.data));

          if (res.data.default_theme === "dark") {
            updateCookiesProperty(CookiesKeyNames.skinMode, "dark");
          }
          updateCookiesProperty(CookiesKeyNames.token, res.data.token);
          updateCookiesProperty(
            CookiesKeyNames.publisherId,
            res.data.publisher_id
          );
          removeCookiesProperty(
            CookiesKeyNames.currentPeriod
          );
          removeCookiesProperty(
            CookiesKeyNames.previousPeriod
          );

          if (
            res.data.role !== "developer" &&
            res.data.role !== "content_manager" &&
            res.data.role !== "sales"
          ) {
            // const navigateLink = isIOSOrSafari() ? "/pub/dashboard/revenue?refresh=true&token=" + res.data.token : "/pub/dashboard/revenue?refresh=true"
            // const navigateLink = "/pub/dashboard/revenue?refresh=true"
            const navigateLink =
              "/pub/dashboard/revenue?pub=" + res.data.publisher_id;

            window.location.href = navigateLink;
            // navigate(navigateLink);
          } else {
            // const navigateLink = isIOSOrSafari() ? "/pub/inventory/queue?refresh=true&token=" + res.data.token : "/pub/inventory/queue?refresh=true"
            const navigateLink =
              "/pub/inventory/queue?pub=" + res.data.publisher_id;

            window.location.href = navigateLink;
            //navigate(navigateLink);
          }
        } else {
          toast.error("Login failed.");
        }
      })
      .catch((err) => {
        toast.error("Login failed.");
        console.log("Error", err);
      });
  };

  let siteLogo = logosModule(publisher_id);

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header className="pb-3">
          <Link to="/" className="header-logo">
            {siteLogo}
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body className="pb-3 pt-3">
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="Enter your email address"
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to="">Forgot password?</Link>
              </Form.Label>
              <Form.Control
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                placeholder="Enter your password"
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              Sign In
            </Button>
            <div className="divider">
              <span>Subject to Terms & Conditions</span>
            </div>
          </Form>
        </Card.Body>
        {/* <Card.Footer className="pt-4">
          Don't have an account? <Link to="/pages/signup">Create One</Link>.
        </Card.Footer> */}
      </Card>
    </div>
  );
};
