import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Row } from "react-bootstrap";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";

import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import InventoryTotals from "./analytics/inventory/InventoryTotals";
import InventoryPerformance from "./analytics/inventory/InventoryPerformance";
import InventoriesPositionsChart from "./analytics/inventory/InventoriesPositionsChart";
import InventoryMap from "./analytics/inventory/InventoryMap";
import DatePicker from "./components/DatePicker";
import InventoriesClickDistributionChart from "./analytics/inventory/InventoriesClickDistributionChart";
import { generateCompatibleColors } from "../helpers/generate-compatible-colors";
import { primaryColorModule } from "../modules/primaryColor";
import { abbreviationsMap } from "../modules/maps";
import Cookies from "universal-cookie";
import {
  CookiesKeyNames,
  isCookiesEnabled,
  useCookiesContext,
} from "../contexts/CookiesContext";

const cookies = new Cookies();

const Ranges = [
  {
    label: "today",
    value: [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    label: "yesterday",
    value: [
      startOfDay(addDays(new Date(), -1)),
      endOfDay(addDays(new Date(), -1)),
    ],
  },
  {
    label: "Last 30 Days",
    value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
  },
];

function getPublisherId() {
  const searchParams = new URLSearchParams(window.location.search);
  const urlDomain = window.location.hostname;
  const pubParam = searchParams.get("pub");
  const publisherIdFromCookies = isCookiesEnabled
    ? cookies.get(CookiesKeyNames.publisherId)
    : localStorage.getItem(CookiesKeyNames.publisherId);

  if (pubParam) {
    if (abbreviationsMap[pubParam]) {
      return abbreviationsMap[pubParam];
    } else {
      return pubParam;
    }
  }

  if (urlDomain === "ppc.hoteltechreport.com") {
    return 67;
  }

  if (publisherIdFromCookies) {
    return Number(publisherIdFromCookies);
  }

  return 1;
}

export default function PublisherInventoryMonitoring() {
  // get 30 days back for begin date
  const [currentPeriod, setCurrentPeriod] = useState([null, null]);
  // get 60 days back for begin date
  const [previousPeriod, setPreviousPeriod] = useState([null, null]);

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const { cookiesData, updateCookiesProperty, getPathKey } =
    useCookiesContext();
  const publisher_id = getPublisherId();

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-UK", options).format(date);
  };

  useEffect(() => {
    const { currentPeriod, previousPeriod } = loadDateRange();
    setCurrentPeriod(currentPeriod);
    setPreviousPeriod(previousPeriod);
  }, []);

  useEffect(() => {
    if (
      currentPeriod &&
      currentPeriod.length === 2 &&
      currentPeriod[0] &&
      currentPeriod[1]
    ) {
      updateCookiesProperty(CookiesKeyNames.currentPeriod, currentPeriod);
      updateCookiesProperty(CookiesKeyNames.previousPeriod, previousPeriod);
    }
  }, [currentPeriod, previousPeriod]);

  const handleOpenDateRangePicker = () => {
    setIsDateRangePickerOpen(true);
  };

  const loadDateRange = () => {
    const storedCurrentPeriod = isCookiesEnabled
      ? cookiesData[getPathKey()]?.[CookiesKeyNames.currentPeriod]
      : JSON.parse(cookiesData[getPathKey()]?.[CookiesKeyNames.currentPeriod]);
    const storedPreviousPeriod = isCookiesEnabled
      ? cookiesData[getPathKey()]?.[CookiesKeyNames.previousPeriod]
      : JSON.parse(cookiesData[getPathKey()]?.[CookiesKeyNames.previousPeriod]);

    if (storedCurrentPeriod && storedPreviousPeriod) {
      return {
        currentPeriod: storedCurrentPeriod.map((date) => new Date(date)),
        previousPeriod: storedPreviousPeriod.map((date) => new Date(date)),
      };
    }

    const defaultCurrentPeriod = [subDays(new Date(), 30), new Date()];
    const defaultPreviousPeriod = [
      subDays(new Date(), 60),
      subDays(new Date(), 30),
    ];

    return {
      currentPeriod: defaultCurrentPeriod,
      previousPeriod: defaultPreviousPeriod,
    };
  };

  const handleDateRange = (e) => {
    const curBegin = new Date(e[0]);
    const curEnd = new Date(e[1]);

    curEnd.setHours(23, 59, 59, 999);

    const diff = curEnd.getTime() - curBegin.getTime() + 1;

    const prevBegin = new Date(curBegin.getTime() - diff);
    const prevEnd = new Date(curEnd.getTime() - diff);

    setPreviousPeriod([prevBegin, prevEnd]);
    setCurrentPeriod([curBegin, curEnd]);
  };

  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode]=== "dark"
    ? "dark"
    : "";
  const [skin, setSkin] = useState(currentSkin);

  const colors = generateCompatibleColors(primaryColorModule(publisher_id), 10);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">PUB Home</Link>
              </li>
              <li className="breadcrumb-item">Dashboard</li>
              <li className="breadcrumb-item active" aria-current="page">
                Inventory
              </li>
            </ol>
            <h2 className="main-title mb-0">Inventory Dashboard</h2>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="btn-outline-primary"
              className={`d-flex align-items-center gap-2 btn btn-outline-primary`}
              onClick={handleOpenDateRangePicker}
            >
              <span className="fw-semibold">Date Range</span>
              <i className="ri-arrow-right-s-line"></i>
              {currentPeriod && currentPeriod.length === 2
                ? ` ${formatDate(currentPeriod[0])} - ${formatDate(
                    currentPeriod[1]
                  )}`
                : ""}
            </Button>
            {isDateRangePickerOpen && (
              <DatePicker
                isDateRangePickerOpen={isDateRangePickerOpen}
                setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                ranges={Ranges}
                onChange={handleDateRange}
                currentPeriod={currentPeriod}
              />
            )}
          </div>
        </div>

        <Row className="g-3 justify-content-center">
          <InventoryTotals
            currentPeriod={currentPeriod}
            previousPeriod={previousPeriod}
            skin={skin}
          />
          <InventoryPerformance currentPeriod={currentPeriod} skin={skin} />
          <Row className="g-3 p-0" style={{ marginTop: "5px" }}>
            <InventoriesPositionsChart
              currentPeriod={currentPeriod}
              colors={colors}
            />
            <InventoriesClickDistributionChart
              currentPeriod={currentPeriod}
              colors={colors}
            />
          </Row>
          <InventoryMap
            currentPeriod={currentPeriod}
            previousPeriod={previousPeriod}
            skin={skin}
          />
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
