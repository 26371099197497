import React, { useEffect, useState } from "react";
import { getAllClientInvoices } from "../../services/finance";
import Header from "../../layouts/Header";
import { switchSkin } from "../../helpers/switch-skin";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import BasicTable from "../../components/BasicTable";
import Footer from "../../layouts/Footer";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  amount: 100,
  account_id: 100,
  campaign_id: 100,
  invoice_no: 100,
  user_email: 100,
  first_name: 100,
  vat_number_valid: 100,
  invoice_address_country_id: 100,
  due_date: 100,
};

const hiddenColumns = [];

const rowFields = [
  "id",
  "amount",
  "account_id",
  "campaign_id",
  "invoice_no",
  "user_email",
  "first_name",
  "last_name",
  "invoice_address_country_id",
  "due_date",
]

export default function Invoices() {
  const title = "Invoices"
  const breadcrumb = "Finance"
  const edit_redirect = "/pub/finance/invoice/preview"
  const breadcrumb_redirect = "/pub/invoices"
  const { cookiesData, getPathKey } = useCookiesContext();

  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark" ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);


  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app d-flex flex-column p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
        </div>

        <BasicTable getData={getAllClientInvoices} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={skin} />

        <Footer />
      </div>
    </React.Fragment>
  )
}