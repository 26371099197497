import React, { useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../scss/components/_ag-table.scss";

export default function UsersSwitchTable({ users, onClick, skin }) {
    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ minHeight: "200px", width: "100%" }), []);

    const [columnDefs, setColumnDefs] = useState([
        { field: 'first_name', headerName: 'First Name', sortable: true, filter: true, resizable: true, width: 200 },
        { field: 'last_name', headerName: 'Last Name', sortable: true, filter: true, resizable: true, width: 200 },
        { field: 'email', headerName: 'Email', sortable: true, filter: true, resizable: true, width: 200 },
        { field: 'role', headerName: 'Role', sortable: true, filter: true, resizable: true, width: 200 },
    ]);

    const [search, setSearch] = useState('');

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
        };
    }, []);

    const onFilterTextBoxChanged = (e) => {
        setSearch(e.target.value);
    };

    const onRowClick = (event) => {
        const id = event.data.id;
        onClick(id);
    };
    
    return (
        <div style={containerStyle}>
            <div
                style={gridStyle}
                className={skin === "dark" ? "ag-theme-quartz-dark ag-theme-quartz" : "ag-theme-quartz-light ag-theme-quartz"}
            >
                <div style={{ marginBottom: "10px" }}>
                    <input
                        type="text"
                        className="input-search"
                        placeholder="Search..."
                        onInput={onFilterTextBoxChanged}
                    />
                </div>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={
                        users.filter((user) => {
                            return (
                                user.first_name.toLowerCase().includes(search.toLowerCase()) ||
                                user.last_name.toLowerCase().includes(search.toLowerCase()) ||
                                user.email.toLowerCase().includes(search.toLowerCase()) ||
                                user.role.toLowerCase().includes(search.toLowerCase())
                            );
                        })
                    }
                    rowHeight={45}
                    onRowClicked={onRowClick}
                    domLayout={"autoHeight"}
                />
            </div>
        </div>
    );
};
