import axios from "axios";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";
import { startOfDayUTC } from "../../helpers/start-of-day-utc";
import { endOfDay } from "date-fns";

async function getPeriodAvgCPC(currentPeriod, previousPeriod) {
    try {
        const token = getToken();
        axios.defaults.headers.common['authorization'] = token;
        const response = await axios.post(process.env.REACT_APP_HUB_URL + '/api/analytics/revenue/period/avg-cpc', {
            current_period: [startOfDayUTC(normalizeToUTC(currentPeriod[0])).getTime(), endOfDay(normalizeToUTC(currentPeriod[1])).getTime()],
            previous_period: [startOfDayUTC(normalizeToUTC(previousPeriod[0]).getTime()), endOfDay(normalizeToUTC(previousPeriod[1])).getTime()]
        })
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodAvgCPC;
