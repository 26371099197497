import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Placeholder } from "react-bootstrap";
import { getPeriodAvgCTR } from "../../../services/micro";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: '2-digit' }; // "Aug 12" format
  return date.toLocaleDateString('en-US', options);
};

const optionCtr = (dataLength, categories) => ({
  chart: {
    parentHeightOffset: 0,
    type: "line",
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  states: {
    hover: { filter: { type: "none" } },
    active: { filter: { type: "none" } },
  },
  colors: ["var(--column-chart)", "var(--primary)"],
  grid: {
    borderColor: "var(--border-chart)",
    padding: { top: 10, bottom: 0, left: 15, right: 15 },
    yaxis: { lines: { show: false } },
  },
  fill: {
    type: ["solid", "gradient"],
    gradient: {
      type: "vertical",
      opacityFrom: 0.35,
      opacityTo: 0.2,
      gradientToColors: ["var(--gradient-to-colors)"],
    },
  },
  stroke: { width: [0, 1.5] },
  xaxis: {
    type: "datetime",
    tickAmount: Math.min(dataLength, 10),
    labels: {
      style: {
        colors: "var(--xaxis-labels)",
        fontSize: "9px",
      },
      formatter: (value) => new Date(value).toLocaleDateString('en-US', { day: '2-digit' }) // Display day number
    },
    axisBorder: { show: false },
    categories: categories, // Set categories dynamically
  },
  yaxis: {
    show: false,
    min: 0,
  },
  legend: { show: false },
  tooltip: {
    shared: true,
    intersect: false,
    x: {
      formatter: (value) => formatDate(value) // Format the tooltip date
    },
    y: {
      formatter: function (value) {
        return value + "%";
      },
    }
  }
});


export default function AverageCTR(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodAvgCTR(currentPeriod, previousPeriod).then((res) => {
          setResponse(res?.data);
        });
      }
    }
  }, [props]);

  if (response && response.periods.length > 0) {
    const periodData = response.periods[0].data;
    const categories = periodData.map(item => new Date(item[0]).toISOString()); // Use ISO strings for x-axis categories

    const parsedDate = response.periods.map(period => {
      const data = period.data.map(item => ({
        x: new Date(item[0]).getTime(),
        y: item[1],
      }));

      return {
        name: period.name,
        type: period.type,
        data: data,
      };
    });

    return (
      <React.Fragment>
        <Card className="card-one">
          <Card.Body className="overflow-hidden">
            <h2 className="card-value mb-1">{response.total_avg_ctr}%</h2>
            <h6 className="text-dark fw-semibold mb-1">Average CTR</h6>
            <p className="fs-xs text-secondary mb-0 lh-4">
              + Average Paid CTR | <b>{response.avg_paid_ctr}%</b>
            </p>

            <ReactApexChart
              series={parsedDate}
              options={optionCtr(periodData.length, categories)}
              height={100}
              className="apex-chart-three"
            />
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
          <Placeholder style={{ height: 205, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
