import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Col, Modal, Nav, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Grid } from "gridjs-react";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

export default function PublisherLeads() {
  const { cookiesData, getPathKey } = useCookiesContext();

  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  const LeadHTMLIframe = ({ fileName, width = "100%", height = "610px" }) => {
    const fileURL = `${process.env.PUBLIC_URL}/leads/lead_form.html`;

    return (
      <div>
        <iframe
          src={fileURL}
          width={width}
          height={height}
          frameBorder="0"
          allowFullScreen
          title="Embedded Lead Form"
        ></iframe>
      </div>
    )
  }

  const [lgShow, setLgShow] = useState(false);
  const lgHandleClose = () => setLgShow(false);

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app d-flex flex-column p-3 p-lg-4">

        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">PUB Home</Link></li>
              <li className="breadcrumb-item">Products</li>
              <li className="breadcrumb-item active" aria-current="page">Leads</li>
            </ol>
            <h2 className="main-title mb-0">Leads</h2>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href=""><i className="ri-printer-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href=""><i className="ri-bar-chart-2-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>New Lead</Tooltip>}>
              <Nav.Link href=""><i className="ri-add-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>

        </div>

        <Row className="g-3 justify-content-left">

          <Col md="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Lead Form Embed</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <Row className="g-3 justify-content-left">
                  <Col md="6">
                    <Form.Label htmlFor="lead_topic">Category</Form.Label><br />
                    <Form.Select name="lead_topic" aria-label="Default select example">
                      <option value="1">Security Awareness Training</option>
                    </Form.Select>
                  </Col>
                  <Col md="6">
                    <Form.Label htmlFor="lead_topic">Preview</Form.Label><br />
                    <div className="d-grid gap-2">
                      <Button variant="outline-primary" onClick={() => setLgShow(true)}>Show Lead Form</Button>
                    </div>
                    <Modal show={lgShow} onHide={lgHandleClose} size="xl">
                      <Modal.Body className="p-4" closeButton>
                        <Card className="card-embed mt-0">
                          <Card.Body className="p-4">
                            <div>
                              <LeadHTMLIframe fileName="lead_form.html" />
                            </div>
                          </Card.Body>
                          <Card.Footer>
                            <pre class="language-html" tabindex="0">
                              <code class="language-html">
                                <span class="token tag">
                                  <span class="token tag">
                                    <span class="token punctuation">&lt;</span>iframe</span>
                                  <span class="token attr-name"> src</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>"/leads/lead_form.html"</span>
                                  <span class="token attr-name"> width</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>"100%"</span>
                                  <span class="token attr-name"> height</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>"625px"</span>
                                  <span class="token attr-name"> frameborder</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>"0"</span>
                                  <span class="token attr-name"> allowfullscreen</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>""</span>
                                  <span class="token attr-name"> title</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>"Training Quote Form"</span><br />
                                  <span class="token attr-name"> name</span>
                                  <span class="token attr-value">
                                    <span class="token punctuation attr-equals">=</span>"278faf45"</span>
                                  <span class="token punctuation">&gt;</span></span>
                                <span class="token tag">
                                  <span class="token tag">
                                    <span class="token punctuation">&lt;/</span>iframe</span>
                                  <span class="token punctuation">&gt;</span>
                                </span>
                              </code>
                            </pre>
                          </Card.Footer>
                        </Card>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Lead Prospects</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Grid
                    data={[
                      ['1', 'John Doe', 'Apple Inc.', '42', 'Commercial', '1 - 6 months', 'john@apple.com', '1234567890'],
                      ['2', 'Jane Doe', 'HP', '30', 'Commercial', '6 - 12 months', 'jane@hp.com', '1234567890'],
                      ['3', 'Jean Duval', 'Revbox', '12', 'Non-Profit', '18+ months', 'jean@revbox.co', '1234567890']
                    ]}
                    columns={['ID', 'Name', 'Company', 'Users', 'Industry', 'Timeframe', 'Email', 'Phone']}
                    search={true}
                    pagination={{
                      enabled: true,
                      limit: 15
                    }}
                    sort={true}
                    className={{
                      table: 'table table-hover mb-0'
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}