import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import Select from "react-select";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link, useLocation } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { editCampaign, getCampaign } from "../../services/adv";
import { toast } from "react-toastify";
import { loadImages } from "../../services/micro";
import { mixpanelInstance } from "../../mixpanel/instance";
import ImageCropModal from "./components/ImageCropModal";
import { arrayMove } from "@dnd-kit/sortable";
import LogoUpload from "./components/LogoUpload";
import ScreenshotsUpload from "./components/ScreenshotUpload";
import TextInput from "./components/TextInput";
import { getPublisher } from "../../services/pub";
import { validateImageDimensions } from "../../helpers/validate-image-dimensions";
import loadMultipleImages from "../../services/micro/load_multiple_images";
import {
  CookiesKeyNames,
  useCookiesContext,
} from "../../contexts/CookiesContext";

export default function AdvertiserProduct() {
  const location = useLocation();
  const [campaignId, setCampaignId] = useState(
    new URLSearchParams(location.search).get("campaignId")
  );
  const [campaign, setCampaign] = useState({});
  const [table, setTable] = useState({});
  const [categoriesIds, setCategoriesIds] = useState([]);
  const { cookiesData, getPathKey } = useCookiesContext();

  const [skin, setSkin] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 100, height: 100 });
  const [imageFiles, setImageFiles] = useState(null);
  const [selectedOption, setSelectedOption] = useState("monthly");
  const logoInputRef = useRef(null);
  const screenshotsInputRef = useRef(null);
  const [croppedLogoPreview, setCroppedLogoPreview] = useState(null);
  const [croppedScreenshotsPreviews, setCroppedScreenshotsPreviews] = useState(
    []
  );

  const [publisher, setPublisher] = useState(null);

  const [croppedLogoFile, setCroppedLogoFile] = useState(null);
  const [croppedScreenshotFiles, setCroppedScreenshotFiles] = useState([]);

  const initialCampaignStateRef = useRef(null);

  const [ref, setRef] = useState(null);

  const screenshotsDimensions = {
    width: publisher?.screenshot_width_dimension ?? 1600,
    height: publisher?.screenshot_height_dimension ?? 900,
  };
  const logoDimensions = {
    width: publisher?.logo_width_dimension ?? 150,
    height: publisher?.logo_height_dimension ?? 150,
  };

  const handleCheckboxChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setCampaignId(urlParams.get("campaignId"));
  }, [location.search]);

  const handleCategoriesChange = (e) => {
    setCategoriesIds(e.map((category) => category.value));
  };

  const trackInputChange = (message, e) => {
    mixpanelInstance.track(message, {
      name: e.target.value,
      campaignId,
    });
  };

  const modalHandleClose = () => setModalShow(false);

  useEffect(() => {
    getCampaign(campaignId).then((res) => {
      setCampaign(res.data);

      initialCampaignStateRef.current = JSON.parse(JSON.stringify(res.data));

      const relatedCategoriesIds = res.data.related_categories_ids;
      setCategoriesIds(relatedCategoriesIds);
      setTable(res.table);
    });
  }, [campaignId]);

  async function getImages() {
    const screens = await loadMultipleImages(
      campaign?.default_screenshot_files
    );

    if (screens) {
      setCroppedScreenshotsPreviews(screens.map((screen) => screen.url));
      setCroppedScreenshotFiles(
        screens.map((screen) => Number(screen.file_id))
      );
    }
  }

  const onNameChange = (e) => {
    setCampaign({ ...campaign, name: e.target.value });

    trackInputChange(`Change name for campaign ${campaign.name}`, e);
  };

  const onPpcDefaultUrlChange = (e) => {
    setCampaign({ ...campaign, ppc_default_url: e.target.value });

    trackInputChange(`Change ppc default url for campaign ${campaign.name}`, e);
  };

  const handleRemoveScreenshot = (index) => {
    setCroppedScreenshotsPreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);

      return updatedPreviews;
    });

    const newFiles = croppedScreenshotFiles.filter((_, i) => i !== index);

    if (newFiles.length === 0) {
      document.getElementById("screenshots-input-placeholder").innerText =
        "No files currently selected for upload";
    }

    setCroppedScreenshotFiles(newFiles);
  };

  const handleDragEnd = (event) => {
    if (!croppedScreenshotsPreviews.length) {
      return;
    }

    const { active, over } = event;
    const oldIndex = croppedScreenshotsPreviews.indexOf(active?.id);
    const newIndex = croppedScreenshotsPreviews.indexOf(over?.id);

    if (oldIndex === -1 || newIndex === -1) {
      return;
    }

    const newPreviews = arrayMove(
      croppedScreenshotsPreviews,
      oldIndex,
      newIndex
    );
    const newFiles = arrayMove(croppedScreenshotFiles, oldIndex, newIndex);
    setCroppedScreenshotsPreviews(newPreviews);
    setCroppedScreenshotFiles(newFiles);
  };

  const onScreenshotsChange = async (e) => {
    const files = e.target.files;
    const totalScreenshots = croppedScreenshotFiles.length + files.length;

    if (files.length > 0 && totalScreenshots <= 4) {
      const newFiles = Array.from(files);
      const { validFiles } = await validateImageDimensions(
        newFiles,
        screenshotsDimensions
      );

      if (validFiles.length > 0) {
        setDimensions(screenshotsDimensions);
        setRef(screenshotsInputRef);
        setImageFiles(validFiles);
        setModalShow(true);
      }
      e.target.value = null;
    } else if (totalScreenshots > 4) {
      e.target.value = null;
      toast.error("You can upload a maximum of 4 files in total!");
    } else {
      e.target.value = null;
    }
  };

  const onLogoChange = async (e) => {
    if (e.target.files.length > 0) {
      const files = Array.from(e.target.files);

      const { validFiles } = await validateImageDimensions(
        files,
        logoDimensions
      );

      if (validFiles.length > 0) {
        setRef(logoInputRef);
        setImageFiles(validFiles);
        setDimensions(logoDimensions);
        setModalShow(true);
      }

      e.target.value = null;
    }
  };

  const handleSaveCroppedImages = (croppedFile, index) => {
    const objectUrl = URL.createObjectURL(croppedFile);

    if (ref === logoInputRef) {
      setCroppedLogoFile(croppedFile);
      setCroppedLogoPreview(objectUrl);
      document.getElementById("logo-input-placeholder").innerText =
        "Logo loaded";
      setCampaign({ ...campaign, logo_file: objectUrl });
    } else if (ref === screenshotsInputRef) {
      setCroppedScreenshotsPreviews((prevPreviews) => {
        const updatedPreviews = [...prevPreviews, objectUrl];

        return updatedPreviews;
      });

      setCroppedScreenshotFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, croppedFile];

        return updatedFiles;
      });

      document.getElementById("screenshots-input-placeholder").innerText =
        "Screenshots loaded";
    }
  };

  const trackSaveChanges = (message) => {
    mixpanelInstance.track(message, {
      name: campaign.name,
      ppc_default_url: campaign.ppc_default_url,
      logo_file: campaign.logo_file,
      default_screenshot_files: campaign.default_screenshot_files,
      related_categories_ids: categoriesIds,
    });
  };

  const handleSaveChanges = () => {
    let updateCampaign = { ...campaign };

    if (campaign.name) {
      updateCampaign.name = campaign.name;
    }

    if (campaign.ppc_default_url) {
      updateCampaign.ppc_default_url = campaign.ppc_default_url;
    }

    if (campaign.logo_file && Number.isInteger(campaign.logo_file)) {
      updateCampaign.logo_file = campaign.logo_file;
    }

    if (campaign.default_screenshot_files) {
      updateCampaign.default_screenshot_files =
        campaign.default_screenshot_files;
    }

    if (categoriesIds) {
      updateCampaign.related_categories_ids = categoriesIds;
    }

    editCampaign(campaignId, updateCampaign)
      .then(async () => {
        toast.success("Changes saved successfully");

        if (croppedLogoFile) {
          const logoResponse = await loadImages([croppedLogoFile]);
          if (logoResponse?.success) {
            updateCampaign.logo_file = Number(logoResponse.data[0].id);
          }
        } else {
          const { logo_file, ...newCampaign } = campaign;

          updateCampaign = newCampaign;
        }

        if (croppedScreenshotFiles.length > 0) {
          const screenshotIds = [];
          for (const croppedFile of croppedScreenshotFiles) {
            if (typeof croppedFile !== "number") {
              const screenshotResponse = await loadImages([croppedFile]);
              if (screenshotResponse?.success) {
                screenshotIds.push(Number(screenshotResponse.data[0].id));
              }
            } else {
              screenshotIds.push(croppedFile);
            }
          }
          updateCampaign.default_screenshot_files = screenshotIds;
        } else {
          updateCampaign.default_screenshot_files = [];
        }

        await editCampaign(campaignId, updateCampaign);
        toast.success("Changes saved successfully");

        initialCampaignStateRef.current = JSON.parse(
          JSON.stringify(updateCampaign)
        );

        trackSaveChanges(`Save changes for campaign ${campaign.id}`);
      })
      .catch((err) => {
        console.error("Error saving changes:", err);
        toast.error("Error saving changes");
        trackSaveChanges(`Error saving changes for campaign ${campaign.id}`);
      });
  };

  useEffect(() => {
    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark"
      ? "dark"
      : "";
    setSkin(currentSkin);
  }, [window.location.pathname]);

  useEffect(() => {
    getImages();
    const handleBeforeUnload = (event) => {
      if (
        JSON.stringify(campaign) !==
        JSON.stringify(initialCampaignStateRef.current)
      ) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [campaign]);

  useEffect(() => {
    getPublisher(Number(cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId]))
      .then((res) => {
        if (res?.success) {
          setPublisher(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [campaignId]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="/adv/home" to={"/adv/home"}>
                  ADV Home
                </Link>
              </li>
              <li className="breadcrumb-item">{campaign?.name}</li>
              <li className="breadcrumb-item active" aria-current="page">
                Product Details
              </li>
            </ol>
            <h2 className="main-title mb-0">Product Details</h2>
          </div>
        </div>

        <ImageCropModal
          modalShow={modalShow}
          modalHandleClose={modalHandleClose}
          imageFiles={imageFiles}
          onSave={handleSaveCroppedImages}
          dimensions={dimensions}
          ref={ref}
        />

        <Row className="g-3 justify-content-center mt-3">
          <Col md="12">
            <Card id="product" className="card-settings">
              <Card.Header>
                <Card.Title>Product Information</Card.Title>
                <Card.Text>
                  This information is used globally for your campaign.
                </Card.Text>
              </Card.Header>
              <Card.Body className="p-0">
                <TextInput
                  label="Product Name"
                  required={true}
                  placeholder="Enter name"
                  value={campaign?.name}
                  onChange={onNameChange}
                />
                <TextInput
                  label="Main Landing Page"
                  required={true}
                  placeholder="http(s)://"
                  value={campaign?.ppc_default_url}
                  onChange={onPpcDefaultUrlChange}
                />
                <LogoUpload
                  onLogoChange={onLogoChange}
                  logoInputRef={logoInputRef}
                  croppedLogoPreview={croppedLogoPreview}
                  logoFile={campaign.logo_file}
                  dimensions={logoDimensions}
                />
                <ScreenshotsUpload
                  onScreenshotsChange={onScreenshotsChange}
                  screenshotsInputRef={screenshotsInputRef}
                  croppedScreenshotsPreviews={croppedScreenshotsPreviews}
                  handleRemoveScreenshot={handleRemoveScreenshot}
                  handleDragEnd={handleDragEnd}
                  dimensions={screenshotsDimensions}
                />
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Categories</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Select
                        id="basic-typeahead-single"
                        onChange={(e) => handleCategoriesChange(e)}
                        options={table?.columns
                          ?.find((column) => {
                            return column.name === "related_categories_ids";
                          })
                          ?.options?.map((option) => {
                            return {
                              label: option.label,
                              value: option.value,
                            };
                          })}
                        isSearchable={true}
                        placeholder={
                          table?.columns?.find((column) => {
                            return column.name === "related_categories_ids";
                          })?.placeholder
                        }
                        selected={categoriesIds}
                        value={table?.columns
                          ?.find((column) => {
                            return column.name === "related_categories_ids";
                          })
                          ?.options?.filter((option) => {
                            return categoriesIds.includes(option.value);
                          })}
                        isMulti
                      />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item d-flex justify-content-end">
                  <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
