import React, { useEffect, useState } from "react";
import { Card, Placeholder, Nav, ListGroup } from "react-bootstrap";

import { getPeriodTopCampaigns } from "../../../services/micro";
import { placeholderLogoModule } from "../../../modules/placeholderLogo";
import { GetPubAbbr } from "../../../helpers/get-pub-abbr";
import {
  CookiesKeyNames,
  useCookiesContext,
} from "../../../contexts/CookiesContext";
import { useRedirectsContext } from "../../../contexts/RedirectsContext";

function getBG(i) {
  const colors = [
    "#ffc107",
    "#0cb785",
    "#f8f9fc",
    "#0dcaf0",
    "#dc3545",
    "#2845f9",
    "#3788d8",
  ];
  return colors[i];
}

export default function TopCampaigns(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState(null);
  const { cookiesData, getPathKey } = useCookiesContext();

  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode]=== "dark"
    ? "dark"
    : "";
  const publisherIdByAbbr = GetPubAbbr();

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (
        currentPeriod[0] &&
        currentPeriod[1] &&
        previousPeriod[0] &&
        previousPeriod[1]
      ) {
        getPeriodTopCampaigns(currentPeriod, previousPeriod)
          .then((res) => {
            if (res && res?.data && res.data.top_campaigns) {
              const campaigns = res.data.top_campaigns.map((item, i) => ({
                id: item.id,
                name: item.name,
                value: item.visual_amount,
                percent: item.difference,
                success: item.difference > 0,
                bg: getBG(i),
                logo: item.logo,
              }));

              setResponse(campaigns);
            } else {
              console.error("Invalid response structure:", res);
              // в мозілі тут саме ця помилка
            }
          })
          .catch((error) => {
            console.error("Error fetching campaigns:", error);
          });
      }
    }
  }, [currentPeriod, previousPeriod, props]);

  const {
    openCampaignAdvAnalytics,
  } = useRedirectsContext();

  const onCampaignClick = (id) => () => {
    openCampaignAdvAnalytics(id);
  };

  if (response) {
    return (
      <React.Fragment>
        <Card className="card-one">
          <Card.Header>
            <Card.Title as="h6">Top 5 Campaign Spend</Card.Title>
            <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ul" className="list-group-one">
              {response.map((item, index) =>
                item.id === "other" ? (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className="px-0 d-flex align-items-center gap-2"
                  >
                    <div>
                      <h6 className="mb-0">{item.name}</h6>
                    </div>
                    <div className="ms-auto text-end">
                      <h6 className="ff-numerals mb-0">${item.value}</h6>
                    </div>
                  </ListGroup.Item>
                ) : (
                  <ListGroup.Item
                    key={index}
                    as="li"
                    className="px-0 d-flex align-items-center gap-2 cursor-pointer"
                    onClick={onCampaignClick(item.id)}
                  >
                    {item.logo ? (
                      <img
                        className={"avatar avatar-rounded text-white"}
                        src={item.logo}
                      />
                    ) : (
                      <div className={"avatar avatar-rounded text-white"}>
                        {placeholderLogoModule(publisherIdByAbbr, currentSkin)}
                      </div>
                    )}
                    <div>
                      <h6 className="mb-0">{item.name}</h6>
                    </div>
                    <div className="ms-auto text-end">
                      <h6 className="ff-numerals mb-0">${item.value}</h6>
                      <small
                        className={
                          "text-" + (item.success ? "success" : "danger")
                        }
                      >
                        {item.percent}%
                      </small>
                    </div>
                  </ListGroup.Item>
                )
              )}
            </ListGroup>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}
