import React from "react";
import { Navigate } from 'react-router-dom';

// Publisher
import Invoice from "../invoices/Invoice";
import PublisherRevenueMonitoring from "../pub/PublisherRevenueMonitoring";
import PublisherCategoryCreate from "../pub/inventory/PublisherCategoryCreate";
import PublisherInventoryMonitoring from "../pub/PublisherInventoryMonitoring";
import PublisherInventoryInsights from "../pub/PublisherInventoryInsights";
import PublisherCustomerMonitoring from "../pub/PublisherCustomerMonitoring";
import PublisherQueue from "../pub/inventory/PublisherQueue";
import PublisherInventory from "../pub/inventory/PublisherInventory";
import PublisherInventoryTypeCreate from "../pub/inventory/PublisherInventoryTypeCreate";
import PublisherInventoryCreate from "../pub/inventory/PublisherInventoryCreate";
import PublisherInventoryCampaign from "../pub/inventory/PublisherInventoryCampaign";
import PublisherCategories from "../pub/inventory/PublisherCategories";
import PublisherWebsites from "../pub/inventory/PublisherWebsites";
import PublisherLeads from "../pub/leads/PublisherLeads";
import PublisherAccounts from "../pub/customers/PublisherAccounts";
import PublisherCampaigns from "../pub/customers/PublisherCampaigns";
import PublisherUsers from "../pub/customers/PublisherUsers";
import PublisherInvoices from "../pub/finance/PublisherInvoices";
import Users from "../pub/Users";


import Transactions from "../pub/finance/Transactions";
import TransactionEdit from "../pub/finance/TransactionEdit";

// Advertiser
import Home from "../adv/Home";
import AdvertiserNewCampaign from "../adv/AdvertiserNewCampaign";
import AdvertiserAnalytics from "../adv/campaign/AdvertiserAnalytics";
import AdvertiserProduct from "../adv/campaign/AdvertiserProduct";
import AdvertiserPpc from "../adv/campaign/AdvertiserPpc";
import AdvertiserInvoices from "../adv/billing/AdvertiserInvoices";
import { AdvertiserSettings } from "../pages/AdvertiserSettings";

// Apps
import Email from "../apps/Email";

// Pages
import PublisherWebsiteCreate from "../pub/inventory/PublisherWebsiteCreate";
import PublisherInvoiceCreate from "../pub/finance/PublisherInvoiceCreate";
import PublisherClientAccountCreate from "../pub/customers/PublisherAccountCreate";
import PublisherCampaignCreate from "../pub/customers/PublisherCampaignCreate";
import PublisherCampaignEdit from "../pub/customers/PublisherCampaignEdit";
import PublisherUserCreate from "../pub/customers/PublisherUserCreate";
import PublisherCategoryEdit from "../pub/inventory/PublisherCategoryEdit";
import PublisherWebsiteEdit from "../pub/inventory/PublisherWebsiteEdit";
import PublisherAccountEdit from "../pub/customers/PublisherAccountEdit";
import PublisherInventoryEdit from "../pub/inventory/PublisherInventoryEdit";
import InventoryCampaignCreate from "../pub/inventory/PublisherQueueCreate";
import InventoryCampaignEdit from "../pub/inventory/PublisherQueueEdit";
import PublisherUserEdit from "../pub/customers/PublisherUserEdit";
import Notifications from "../pages/Notifications";
import BillingForm from "../components/BillingForm";
import BillingMethods from "../pub/finance/BillingMethods";
import BillingMethodCreate from "../pub/finance/BillingMethodCreate";
import CampaignActivation from "../pub/finance/CampaignActivation";
import Invoices from "../pub/finance/Invoices";
import InvoicePreview from "../pub/finance/InvoicePreview";
import BillingMethodEdit from "../pub/finance/BillingMethodEdit";
import TransactionCreate from "../pub/finance/TransactionCreate";
import ClusterStatistics from "../adv/campaign/ClusterStatistic";
import { GlobalConfigurations } from "../pages/GlobalConfigurations";
import { Automations } from "../pages/Automations";

const protectedRoutes = [
  { path: "/", element: <Navigate to="/pages/signin" replace /> },
  { path: "invoices/invoice", element: <Invoice /> },
  { path: "adv/notifications", element: <Notifications /> },
  { path: "adv/notifications/emails", element: <Email /> },
  { path: "pub/notifications", element: <Notifications /> },
  { path: "pub/notifications/emails", element: <Email /> },
  { path: "pub/finance/billing", element: <BillingMethods /> },
  { path: "pub/finance/billing/new", element: <BillingMethodCreate /> },
  { path: "pub/finance/billing/edit", element: <BillingMethodEdit /> },
  { path: "adv/finance/billing", element: <BillingMethods /> },
  { path: "adv/finance/billing/new", element: <BillingMethodCreate /> },
  { path: "adv/finance/billing/edit", element: <BillingMethodEdit /> },
  { path: "pub/finance/transactions", element: <Transactions /> },
  { path: "pub/finance/transaction/edit", element: <TransactionEdit /> },
  { path: "pub/finance/transaction/new", element: <TransactionCreate /> },
  { path: "adv/finance/transaction/edit", element: <TransactionEdit /> },
  { path: "pub/finance/invoices", element: <Invoices /> },
  { path: "adv/finance/invoices", element: <Invoices /> },
  { path: "pub/finance/invoice/preview", element: <InvoicePreview /> },
  { path: "adv/finance/invoice/preview", element: <InvoicePreview /> },
  { path: "adv/campaign/activate", element: <CampaignActivation /> },
  { path: "pub/dashboard/revenue", element: <PublisherRevenueMonitoring /> /*<InDevelopment/>*/ },
  { path: "pub/dashboard/inventory", element: <PublisherInventoryMonitoring /> /*<InDevelopment/>*/ },
  { path: "pub/dashboard/inventory/insights", element: <PublisherInventoryInsights /> },
  { path: "pub/dashboard/campaigns", element: <PublisherCustomerMonitoring /> /*<InDevelopment/>*/ },
  { path: "pub/inventory/queue", element: <PublisherQueue /> },
  { path: "pub/invetory/campaign/new", element: <InventoryCampaignCreate /> },
  { path: "pub/invetory/campaign/edit", element: <InventoryCampaignEdit /> },
  { path: "pub/inventory/all", element: <PublisherInventory /> },
  { path: "pub/inventory/type", element: <PublisherInventoryTypeCreate /> },
  { path: "pub/inventory/new", element: <PublisherInventoryCreate /> },
  { path: "pub/inventory/edit", element: <PublisherInventoryEdit /> },
  { path: "pub/inventory/components/1", element: <PublisherInventoryCampaign /> },
  { path: "pub/category/new", element: <PublisherCategoryCreate /> },
  { path: "pub/category/edit", element: <PublisherCategoryEdit /> },
  { path: "pub/website/new", element: <PublisherWebsiteCreate /> },
  { path: "pub/website/edit", element: <PublisherWebsiteEdit /> },
  { path: "pub/invoice/new", element: <PublisherInvoiceCreate /> },
  { path: "/pub/customer/new", element: <PublisherClientAccountCreate /> },
  { path: "/pub/customer/edit", element: <PublisherAccountEdit /> },
  { path: "/pub/customer/campaign/new", element: <PublisherCampaignCreate /> },
  { path: "/pub/customer/campaign/edit", element: <PublisherCampaignEdit /> },
  { path: "/pub/customer/user/new", element: <PublisherUserCreate /> },
  { path: "/pub/customer/user/edit", element: <PublisherUserEdit /> },
  { path: "pub/categories", element: <PublisherCategories /> },
  { path: "pub/websites", element: <PublisherWebsites /> },
  { path: "pub/leads", element: <PublisherLeads /> },
  { path: "pub/customers/accounts", element: <PublisherAccounts /> },
  { path: "pub/customers/campaigns", element: <PublisherCampaigns /> },
  { path: "pub/customers/users", element: <PublisherUsers /> },
  { path: "pub/users", element: <Users /> },
  { path: "adv/home", element: <Home /> },
  { path: "adv/campaign/new", element: <AdvertiserNewCampaign /> },
  { path: "adv/campaign/analytics", element: <AdvertiserAnalytics /> },
  { path: "adv/campaign/product", element: <AdvertiserProduct /> },
  { path: "adv/campaign/ppc", element: <AdvertiserPpc /> },
  { path: "adv/campaign/ppc/clustering", element: <ClusterStatistics /> },
  { path: "adv/billing/new", element: <BillingForm /> },
  { path: "adv/billing/invoices", element: <AdvertiserInvoices /> },
  { path: "adv/settings", element: <AdvertiserSettings /> },
  { path: "adv/configurations", element: <GlobalConfigurations /> },
  { path: "adv/automations", element: <Automations /> },
  { path: "pub/settings", element: <AdvertiserSettings /> },
  { path: "pub/configurations", element: <GlobalConfigurations /> },
  { path: "pub/automations", element: <Automations /> },
]

export default protectedRoutes;