import axios from "axios";
import { getToken } from "../../helpers/get-token";

async function getRecentNotifications() {
  try {
    const token = getToken();
    axios.defaults.headers.common["authorization"] = token;
    const response = await axios.get(
      process.env.REACT_APP_HUB_URL + "/api/notifications/recent"
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export default getRecentNotifications;
