import React, { useEffect, useState } from "react";
import { Col, Card, Nav, Placeholder } from "react-bootstrap";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import getInventoriesClicksDistribution from "../../../../services/micro/get_inventory_clicks_distribution";
import ReactApexChart from "react-apexcharts";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

export default function SpecificInventoryClickDistributionChart({
  currentPeriod,
  colors,
  inventoryId,
}) {
  const [response, setResponse] = useState();
  const [reset, setReset] = useState(0);

  useEffect(() => {
    if (currentPeriod && inventoryId && currentPeriod[0] && currentPeriod[1]) {
      getInventoriesClicksDistribution(currentPeriod, inventoryId).then(
        (response) => {
          if (response) {
            setResponse(response.data);
          }
        }
      );
    }
  }, [currentPeriod]);

  if (!response) {
    return (
      <React.Fragment>
        <Placeholder
          as={Card.Title}
          animation="glow"
          style={{ color: "var(--bs-body-color)" }}
        >
          <Placeholder style={{ height: 400, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  } else {
    const minCpc = Math.min(
      ...response.map((dataset) => Math.min(...dataset.map((item) => item.cpc)))
    );
    const maxCpc = Math.max(
      ...response.map((dataset) => Math.max(...dataset.map((item) => item.cpc)))
    );

    const optionMetrics = {
      chart: {
        parentHeightOffset: 0,
        type: "line",
        toolbar: { show: false },
        stacked: false,
        zoom: {
          enabled: false,
        },
      },
      colors: colors,
      grid: {
        borderColor: "rgba(72,94,144, 0.08)",
        yaxis: {
          lines: { show: false },
        },
        padding: { top: -20 },
      },
      stroke: {
        width: 2,
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
        decimalsInFloat: 0,
        labels: {
          style: {
            colors: "#6e7985",
            fontSize: "11px",
          },
        },
      },
      yaxis: {
        min: minCpc - 1,
        max: maxCpc + 1,
      },
      dataLabels: { enabled: false },
      legend: { show: false },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        y: {
          formatter: function (val) {
            return "$" + Number(val).toFixed(2);
          },
        },
      },
    };

    const lines = response.map((dataset, index) => ({
      data: dataset.map((item) => ({ x: new Date(item.date), y: item.cpc })),
      name: `Position ${index + 1}`,
    }));

    return (
      <Col xl="7">
        <Card className="card-one">
          <Card.Header>
            <Card.Title as="h6">CPC Development by Position</Card.Title>
            <Nav className="nav-icon nav-icon-sm ms-auto">
              <Nav.Link href="">
                <i
                  className="ri-refresh-line"
                  onClick={() => setReset(reset + 1)}
                ></i>
              </Nav.Link>
            </Nav>
          </Card.Header>
          <Card.Body className="p-3">
            <div className="ht-350">
              <ReactApexChart
                key={reset}
                series={lines}
                options={optionMetrics}
                type="line"
                height={365}
                className="apex-chart-two"
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}
