import React, { useEffect, useState } from "react";
import AdvertiserPpc from "../../adv/campaign/AdvertiserPpc";
import { mixpanelInstance } from "../../mixpanel/instance";
import '../loader.css';

export default function CustomerIframe() {
  // get inventory id and campaing id from url
  const [iframe, setIframe] = useState(null);

  useEffect(() => {
    setIframe(1);
    mixpanelInstance.track("Customer PPC Auctions Page Loaded");
  }, []);

  if (iframe === 1) {
    return (
      <AdvertiserPpc isIframe={true} />
    )
  } else {
    return (
      <div>
        <span class="loader"></span>
      </div>
    )
  }
}