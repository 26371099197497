export const getDefaultPeriods = () => {
  const curBegin = new Date();
  const curEnd = new Date();

  curBegin.setHours(0, 0, 0, 0);
  curEnd.setHours(23, 59, 59, 999);

  const diff = curEnd.getTime() - curBegin.getTime() + 1;

  const prevBegin = new Date(curBegin.getTime() - diff);
  const prevEnd = new Date(curEnd.getTime() - diff);

  return {
    currentPeriod: [curBegin, curEnd],
    previousPeriod: [prevBegin, prevEnd],
  };
};
