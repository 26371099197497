import React, { useState, useLayoutEffect, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  publisherDashboardMenu,
  publisherCustomerMenu,
  publisherDefaultFinanceMenu,
  publisherInventoryMenu,
  publisherProductsMenu,
  advertiserDashboardMenu,
  advertiserCampaignMenu,
  advertiserBillingMenu,
  adminMenu,
  applicationsMenu,
  pagesMenu,
  uiElementsMenu,
  publisherFinanceMenu,
  publisherHTRFinanceMenu,
  advertiserFinanceMenu,
} from "../data/Menu";
import { getUserName } from "../helpers/user-name";
import { getUserRole } from "../helpers/user-role";
import { getAllCampaigns } from "../services/adv";
import { logosModule } from "../modules/logos";
import { GetPubAbbr } from "../helpers/get-pub-abbr";
import { addPubParamToUrl } from "../helpers/add-pub-param-to-url";
import {
  CookiesKeyNames,
  isCookiesEnabled,
  useCookiesContext,
} from "../contexts/CookiesContext";
import { getAllRoles } from "../services/micro";
import Cookies from "universal-cookie";

const PUBLISHER = "PUBLISHER";
const ADMIN = "ADMIN";
const ADVERTISER = "CLIENT";
const NONE = "NONE";

const cookies = new Cookies();

export const Sidebar = () => {
  const {
    cookiesData,
    getPathKey,
  } = useCookiesContext();

  const [subRoles, setSubRoles] = useState([]);
  const user = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [scrollBarRef, setScrollBarRef] = useState(null);
  const publisherIdByAbbr = GetPubAbbr();
  const [menuOpened, setMenuOpened] = useState(false);
  const [publisherId, setPublisherId] = useState(null);
  const [currentSkin, setCurrentSkin] = useState(null);
  const [storedAdvId, setStoredAdvId] = useState(null);

  useEffect(() => {
    const publisherIdByCookies =
      cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];
    const advIdByLStorage =
      cookiesData[getPathKey()]?.[CookiesKeyNames.advertiserId];
    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode];
    const publisherId = publisherIdByAbbr || publisherIdByCookies;
    setPublisherId(publisherId);
    setCurrentSkin(currentSkin);
    setStoredAdvId(advIdByLStorage);
  }, [window.location.pathname]);

  let siteLogo = logosModule(publisherId, currentSkin);

  useLayoutEffect(() => {
    if (!storedAdvId) {
      setData([]);
      return;
    } else {
      getAllCampaigns(storedAdvId)
        .then((res) => {
          setData(res.db_data);
        })
        .catch((e) => {
          toast.error("Server error");
        });
    }
  }, [storedAdvId]);

  // avaliable_accesses
  // "user_account_management_access"
  // "finance_access"
  // "dashboards_access"
  // "inventory_access"
  // "advertiser_access"
  // "customers_access"
  // "user_roles_management_access"
  // "global_variables_access"
  // "webhook_access"
  // "api_key_access"
  // "notifications_access"

  useEffect(() => {
    getAllRoles(storedAdvId)
      .then((res) => {
        setSubRoles(res.data);
      })
      .catch((e) => {
        toast.error("Server error");
      });
  }, []);

  const toggleFooterMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
    setMenuOpened(!menuOpened);
  };

  const userAccesses = subRoles.find(
    (subRole) => subRole.name == user.role
  )?.avaliable_accesses;
  const homePath = storedAdvId ? `/adv/home` : `/pub/dashboard/revenue`;
  const accountSettingsPath = storedAdvId ? `/adv/settings` : `/pub/settings`;
  const globalConfigurationsPath = storedAdvId ? `/pub/configurations` : '/pub/configurations';
  const automationsPath = storedAdvId ? `/adv/automations` : '/pub/automations';
  const automationsPermission =
    userAccesses &&
    (userAccesses.includes("api_key_access") ||
      userAccesses.includes("webhook_access") ||
      userAccesses.includes("ai_automations"));

  const globalConfigurationsPermission =
    userAccesses && userAccesses.includes("global_variables_access");

  let marginForSidebarFooter = 0;

  if (!automationsPermission) marginForSidebarFooter += 35;
  if (!globalConfigurationsPermission) marginForSidebarFooter += 35;
  if (!userAccesses?.includes("api_key_access")) marginForSidebarFooter += 35;

  window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
      sidebar.classList.remove("footer-menu-show");
      setMenuOpened(false);
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
      document.querySelector("body").classList.remove("sidebar-show");
    }
  });

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Link to={homePath} className="sidebar-logo">
          {/* <object type="image/svg+xml" data={siteLogo} className="w-100" aria-label="svg image"></object>  */}
          {siteLogo}
        </Link>
      </div>
      <PerfectScrollbar
        className="sidebar-body"
        ref={(ref) => setScrollBarRef(ref)}
      >
        {data && user && (
          <SidebarMenu
            onUpdateSize={() => scrollBarRef.updateScroll()}
            userAccesses={userAccesses}
            advertiserId={storedAdvId}
            campaigns={data}
            publisherId={publisherId}
          />
        )}
      </PerfectScrollbar>
      <div
        className="sidebar-footer"
        style={{
          marginBottom:
            user?.role !== PUBLISHER ? `${marginForSidebarFooter}px` : 0,
        }}
      >
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb avatar online">
            <span className="avatar-initial bg-primary">
              {getUserName(user)[0]}
            </span>
          </div>
          <div className="sidebar-footer-body">
            <h6>
              <Link to={accountSettingsPath}>{getUserName(user)}</Link>
            </h6>
            <p>{getUserRole(user)}</p>
          </div>
          <Link onClick={toggleFooterMenu} to="" className="dropdown-link">
            <i
              className={
                menuOpened ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"
              }
            ></i>
          </Link>
        </div>
        <div className="sidebar-footer-menu">
          <nav className="nav">
            {userAccesses && userAccesses.includes("api_key_access") && (
              <Link
                to="https://docs.revbox.co/docs/intro/?code=XrUo7zIhhU<"
                target="_blank"
              >
                <i className="ri-question-line"></i> Documentation
              </Link>
            )}
          </nav>
          <hr />
          <nav className="nav">
            {/* <Link to="/adv/settings"><i className="ri-refund-line"></i> Revbox Billing</Link> */}
            <Link to={accountSettingsPath}>
              <i className="ri-user-settings-line"></i> Account Settings
            </Link>
            {/* {
                            userAccesses && userAccesses.includes("customers_access") &&
                            <Link to="/pub/customer/campaign/new"><i className="ri-add-line"></i> New Campaign</Link>
                        } */}
          </nav>
          {globalConfigurationsPermission && (
            <nav className="nav">
              <Link to={globalConfigurationsPath}>
                <i className="ri-settings-4-line"></i> Global Configurations
              </Link>
            </nav>
          )}

          {automationsPermission && (
            <nav className="nav">
              <Link to={automationsPath}>
                <i className="ri-code-s-slash-line"></i> Automations
              </Link>
            </nav>
          )}
          <hr />
          <nav className="nav">
            <Link to={`/pages/signin?pub=${user.publisher_id}`}>
              <i className="ri-logout-box-r-line"></i> Log Out
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

const SidebarMenu = (props) => {
  const { updateCookiesProperty } = useCookiesContext();
  const location = useLocation();
  const [isCampaignsHidden, setIsCampaignsHidden] = useState(true);
  const userAccesses = props.userAccesses;
  const advertiserId = props.advertiserId;
  const publisherId = props.publisherId;
  const campaigns = props.campaigns;

  let sidebarLogo;

  const toggleCampaigns = (e) => {
    e.preventDefault();
    setIsCampaignsHidden(!isCampaignsHidden);
    props.onUpdateSize();
  };

  const addRoleSign = (advertiserId) => {
    let content = "";

    if (advertiserId) {
      content = "ADV";
    } else {
      content = "PUB";
    }

    const existingRoleSign = document.querySelector(".role-sign");
    if (existingRoleSign) {
      existingRoleSign.remove();
    }

    const roleSign = document.createElement("div");
    roleSign.className = "role-sign";
    roleSign.textContent = content;

    sidebarLogo.appendChild(roleSign);
  };

  useEffect(() => {
    sidebarLogo = document.querySelector(".sidebar-logo");

    addRoleSign(advertiserId);
  }, [advertiserId]);

  useEffect(() => {
    if (location.pathname.includes("/adv/")) {
      updateCookiesProperty(
        CookiesKeyNames.sidebarSkin,
        "advertiser",
        advertiserId
      );
    }
  }, [location.pathname]);

  const currentUrlParams = new URLSearchParams(window.location.search);
  const pubValue = currentUrlParams.get("pub");

  const populateMenu = (m) => {
    const menu = m.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink
              to={addPubParamToUrl(sm.link, pubValue, advertiserId)}
              className="nav-sub-link"
              key={key}
            >
              {sm.label}
            </NavLink>
          );
        });
      }

      if (m.link.includes("campaignId")) {
        const campaignId = currentUrlParams.get("campaignId");
        const urlPath = window.location.pathname;
        const linkCampaignId = m.link.split("?")[1].split("=")[1];

        if (campaignId === linkCampaignId) {
          if (urlPath === m.link.split("?")[0]) {
            return (
              <li key={key} className="nav-item">
                {!sm ? (
                  <NavLink
                    to={addPubParamToUrl(m.link, pubValue, advertiserId)}
                    className="nav-link active"
                  >
                    <i className={m.icon}></i> <span>{m.label}</span>
                  </NavLink>
                ) : (
                  <div
                    onClick={toggleSubMenu}
                    className="nav-link has-sub active"
                  >
                    <i className={m.icon}></i> <span>{m.label}</span>
                  </div>
                )}
                {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
              </li>
            );
          }
        }

        return (
          <li key={key} className="nav-item">
            {!sm ? (
              <NavLink
                to={addPubParamToUrl(m.link, pubValue, advertiserId)}
                className="nav-non-active-link"
              >
                <i className={m.icon}></i> <span>{m.label}</span>
              </NavLink>
            ) : (
              <div
                onClick={toggleSubMenu}
                className="nav-non-active-link has-sub"
              >
                <i className={m.icon}></i> <span>{m.label}</span>
              </div>
            )}
            {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
          </li>
        );
      }

      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink
              to={addPubParamToUrl(m.link, pubValue, advertiserId)}
              className="nav-link"
            >
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    props.onUpdateSize();
  };

  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    props.onUpdateSize();
  };

  return (
    <React.Fragment>
      {advertiserId &&
        userAccesses &&
        userAccesses.includes("dashboards_access") && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Dashboard
            </div>
            {populateMenu(advertiserDashboardMenu)}
          </div>
        )}
      {advertiserId &&
        userAccesses &&
        userAccesses.includes("advertiser_access") &&
        campaigns && (
          <div className={`nav-group ${isCampaignsHidden ? "show" : ""}`}>
            <div className="nav-label" onClick={toggleCampaigns}>
              Campaigns
            </div>
            {advertiserId &&
              userAccesses &&
              userAccesses.includes("advertiser_access") &&
              isCampaignsHidden &&
              campaigns.map((campaign, key) => {
                return (
                  <div key={key}>
                    <div className="campaign-nav-label">
                      <div className="d-flex flex-column">
                        <Badge
                          bg="campaign-name"
                          className="me-1 bg-campaign-name"
                          style={{
                            width: "max-content",
                            maxWidth: "180px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            pointerEvents: "none",
                          }}
                        >
                          {campaign.name}
                        </Badge>
                      </div>
                    </div>
                    {populateMenu(
                      advertiserCampaignMenu.map((m) => {
                        if (m.link.includes("campaignId")) {
                          m.link = m.link.split("?")[0];
                        }
                        m.link = `${m.link}?campaignId=${campaign.id}`;
                        return m;
                      })
                    )}
                  </div>
                );
              })}
          </div>
        )}
      {!advertiserId &&
        userAccesses &&
        userAccesses.includes("dashboards_access") && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Dashboards
            </div>
            {populateMenu(publisherDashboardMenu)}
          </div>
        )}
      {!advertiserId &&
        userAccesses &&
        userAccesses.includes("inventory_access") && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Inventory
            </div>
            {populateMenu(publisherInventoryMenu)}
          </div>
        )}
      {!advertiserId &&
        userAccesses &&
        userAccesses.includes("customers_access") && (
          <div className="nav-group show">
            <div className="nav-label" onClick={toggleMenu}>
              Customers
            </div>
            {populateMenu(publisherCustomerMenu)}
          </div>
        )}
      {userAccesses && userAccesses.includes("finance_access") && (
        <div className="nav-group show">
          <div className="nav-label" onClick={toggleMenu}>
            Finances
          </div>
          {advertiserId &&
          userAccesses &&
          userAccesses.includes("advertiser_access")
            ? populateMenu(advertiserFinanceMenu)
            : publisherId == 67 || publisherId == 10
            ? populateMenu(publisherHTRFinanceMenu)
            : populateMenu(publisherDefaultFinanceMenu)}
        </div>
      )}
      {/*{((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Products</div>
                    {populateMenu(publisherProductsMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show account">
                    <div className="nav-label" onClick={toggleMenu}>Account</div>
                    {populateMenu(advertiserDashboardMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}><Badge bg="primary" className="me-1">ABC Bank</Badge> Campaigns</div>
                    {populateMenu(advertiserCampaignMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Billing</div>
                    {populateMenu(advertiserBillingMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Admin</div>
                    {populateMenu(adminMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Inventory</div>
                    {populateMenu(applicationsMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Customers</div>
                    {populateMenu(pagesMenu)}
                </div>
            }
            {((userRole === NONE)) &&
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Billing</div>
                    {populateMenu(uiElementsMenu)}
                </div>
            } */}
    </React.Fragment>
  );
};

window.addEventListener("load", function () {
  let HTMLTag = document.querySelector("html");
  let skinMode = isCookiesEnabled
    ? cookies.get("pub")?.[CookiesKeyNames.skinMode]
    : JSON.parse(localStorage.getItem("pub") || "{}")[CookiesKeyNames.skinMode];

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});
